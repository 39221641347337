import React from 'react';

import styles from './centered_hero.module.less';

const CenteredHero = ({ bgImage, children, content, headline, kicker }) => {
  let renderContent = children;

  if (!renderContent && content) {
    renderContent = <div className={styles.content}>{content}</div>;
  }

  if (!renderContent && headline && kicker) {
    renderContent = (
      <div className={styles.content}>
        <h2>{kicker}</h2>
        <h1>{headline}</h1>
      </div>
    );
  }

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url('${bgImage}')` }}>
      {renderContent}
    </div>
  );
};

export { CenteredHero };
