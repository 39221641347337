import React, { useState } from 'react';
import classNames from 'classnames';
import './plan-selected.less';
import { Button } from '../Button';
import { PlanPrice } from '../PlanPrice';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';

export const PlanSelected = ({
  style,
  className,
  plan,
  accordion,
  buttons = true,
  prices = false,
}) => {
  const { id, name, type, description, features, action } = plan;
  const [open, setOpen] = useState(false);

  const hasDetails = description || features.length > 0 || prices;

  return (
    <div
      key={id}
      style={style}
      className={classNames('planSelected', className)}>
      <div className="planSelected__top">
        <div className="planSelected__type subtitle">
          {type} {type != 'payment' ? 'program' : ''}
        </div>
        <div
          className="planSelected__name"
          style={{ cursor: accordion && hasDetails ? 'pointer' : 'default' }}
          onClick={() => {
            if (!accordion || !hasDetails) return;
            setOpen(!open);
          }}>
          <span className="name">
            {type != 'payment' && 'nue.'}
            <span className="italic">{name.replace('nue.', '')}</span>
          </span>
          {accordion && hasDetails && (
            <span className="details">
              <span className="text">Details</span>
            </span>
          )}
        </div>
      </div>

      {open && (
        <>
          <Divider sx={{ margin: '10px 0', borderColor: '#0D345C55' }} />
          <p className="planSelected__description">{description}</p>

          <ul className="planSelected__features">
            {features.map((feature) => (
              <li key={feature.name} className="plan__feature">
                {feature.name}
              </li>
            ))}
          </ul>

          {prices && <PlanPrice plan={plan} />}
          {buttons && action === 'checkout' && (
            <Button to={`/checkout/${id}`} as={Link}>
              Choose this plan
            </Button>
          )}

          {buttons && action === 'contact' && (
            <Button to="/talk-with-us" as={Link}>
              Contact us to sign up
            </Button>
          )}
        </>
      )}
    </div>
  );
};