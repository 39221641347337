import React from 'react';

import { Helmet } from 'react-helmet-async';

import { useLocation } from 'react-router-dom';

const defaultDescription =
  'Nue Life - A mental health wellness company offers online ketamine treatment and psychedelic therapy at home. Book a free discovery call now!';

export const MetaHeader = ({ title, description = defaultDescription }) => {
  const location = useLocation();

  const combinedTitle = `${title} | Nue Life`;

  const url = `https://${window.location.hostname}${location.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />;
      <meta name="title" content={combinedTitle} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        property="og:title"
        content="Nue Life - It’s your right to feel good."
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="/assets/landing-hero-og.jpg" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content="/assets/landing-hero-og.jpg" />
    </Helmet>
  );
};