import { hot } from 'react-hot-loader/root';

import React, { useLayoutEffect, useEffect, lazy } from 'react';

import {
  Switch,
  Route,
  useHistory,
  Redirect,
  useLocation,
} from 'react-router-dom';

const Landing = lazy(() => import('screens/Landing'));
const NewsletterSignup = lazy(() => import('screens/NewsletterSignup'));
const Checkout = lazy(() => import('screens/SignupFlows/Checkout'));
const ThankYouForYourInterest = lazy(() =>
  import('screens/ThankYouForYourInterest')
);
const PaymentCompleted = lazy(() => import('screens/PaymentCompleted'));
const CallBooked = lazy(() => import('screens/CallBooked'));
const CallSuccess = lazy(() => import('screens/CallSuccess'));
const ResetPassword = lazy(() => import('screens/Auth/ResetPassword'));
const Unsubscribe = lazy(() => import('screens/Unsubscribe'));
const ForgotPassword = lazy(() => import('screens/Auth/ForgotPassword'));
const Setup = lazy(() => import('screens/Setup'));
const FinalizeAccount = lazy(() => import('screens/FinalizeAccount'));
const ResiliencePage = lazy(() => import('screens/Resilience'));
const NotFound = lazy(() => import('screens/NotFound'));
const Support = lazy(() => import('screens/Support'));
const Test = lazy(() => import('screens/Test'));
const PlaylistTest = lazy(() => import('screens/PlaylistTest'));
const ChiliPiperBook = lazy(() => import('screens/Landing/ChiliPiperBook'));
const TalkToUs = lazy(() => import('screens/TalkToUs/TalkToUs'));
const AppOnlySignUp = lazy(() => import('screens/TalkToUs/FreeUserSignUp'));
const OurPrograms = lazy(() => import('screens/OurPrograms'));
const FreeUserCheckout = lazy(() => 'screens/TalkToUs/FreeUserCheckout');
const CheckoutReset = lazy(() => import('../../screens/checkout-reset'));
const Coach = lazy(() => import('screens/Coach'));
import { MetaHeader } from '../../components';

function redirectTo(from, to) {
  return () => {
    const currentPath = window.location.pathname.replace(from, '');
    const currentSearch = window.location.search;
    const currentHash = window.location.hash;

    const redirectUrl = `${to}${currentPath}${currentSearch}${currentHash}`;
    window.location.href = redirectUrl;
    return <></>;
  };
}

function App() {
  const history = useHistory();

  useEffect(() => {
    // To track the subsequent pageviews
    history.listen(trackPageView);
  }, [history]);

  function trackPageView() {
    window?.fbq('track', 'PageView');
  }

  const location = useLocation();

  useLayoutEffect(() => {
    localStorage.removeItem('form_variant');
    console.info("Entrypoint URL: ", window.location.href);
  }, []);

  function getTitle() {
    const lastPartOfUrl = location.pathname.split('/');
    if (lastPartOfUrl.length > 1) {
      return lastPartOfUrl[1].replace('_', ' ').replace('-', ' ');
    }
    return 'Nuelife';
  }

  return (
    <>
      <MetaHeader title={getTitle()} description={getTitle()}></MetaHeader>
      <Switch>
        {/* Routes */}
        <Route path="/" component={Landing} exact />
        <Route path="/__test" component={Test} exact />
        <Route path="/__playlist-test" component={PlaylistTest} exact />
        <Route path="/support" component={Support} exact />
        <Route path="/resilience" component={ResiliencePage} exact />
        <Route path="/reset-checkout" component={CheckoutReset} exact />
        <Route path="/programs" component={OurPrograms} exact />
        <Route path="/coaches" component={Coach} exact />
        <Route path="/setup" component={Setup} exact />
        <Route path="/new-setup" component={FinalizeAccount} exact />
        <Route path="/newsletter/signup" component={NewsletterSignup} exact />
        <Route path="/talk-to-us/:step?" component={TalkToUs} exact />
        <Route
          path="/free-user/sign-up/:step?"
          component={AppOnlySignUp}
          exact
        />
        <Route
          path="/free-user/checkout/account/:step?"
          component={FreeUserCheckout}
          exact
        />
        <Route
          path="/free-user/checkout/:plan/:step?"
          component={FreeUserCheckout}
          exact
        />
        <Route path="/checkout/:plan/:step?" component={Checkout} exact />
        <Route
          path="/thank-you-for-your-interest"
          component={ThankYouForYourInterest}
          exact
        />
        <Route path="/payment/success" component={PaymentCompleted} exact />
        <Route path="/call/booked" component={CallBooked} exact />
        <Route path="/call/success" component={CallSuccess} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <Route path="/reset-password" component={ResetPassword} exact />
        <Route path="/unsubscribe" component={Unsubscribe} exact />
        <Route path="/call-cp" component={ChiliPiperBook} exact />

        {/* Redirects */}
        <Route
          path="/care"
          component={redirectTo('/care', 'https://web.nue.life/care')}
        />
        <Route
          path="/reflect"
          component={redirectTo(
            '/reflect',
            'https://web.nue.life/patient/home'
          )}
        />
        <Route
          path="/experience"
          component={redirectTo(
            '/experience',
            'https://web.nue.life/patient/experience'
          )}
        />
        <Route
          path="/listen"
          component={redirectTo(
            '/listen',
            'https://web.nue.life/patient/playlists'
          )}
        />
        <Route
          path="/messages"
          component={redirectTo(
            '/messages',
            'https://web.nue.life/patient/chat'
          )}
        />
        <Route
          path="/journal"
          component={redirectTo('/journal', 'https://nue.life/journals')}
        />
        <Route
          path="/learn"
          component={redirectTo('/learn', 'https://nue.life/journals')}
        />
        <Route
          path="/talk-with-us"
          component={redirectTo('/talk-with-us', 'https://nue.life/talk-to-us')}
        />
        <Route
          path="/discover"
          component={redirectTo('/discover', 'https://nue.life/signup')}
        />

        <Redirect from="/home" to="/" />
        <Redirect from="/meeting" to="/" />
        <Redirect from="/messages" to="/" />
        <Redirect from="/programs" to="/" />
        <Redirect from="/listen" to="/" />
        <Redirect from="/experience" to="/" />
        <Redirect from="/reflect" to="/" />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default hot(App);