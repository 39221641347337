import React, { useEffect, useState } from 'react';
import './top-nav.less';

import { Layout } from '../Layout';
import {
  LogoWithText,
  IconMenu,
  IconClose,
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconTwitter,
} from '../Icons';
import { Button } from '../Button';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

export const TopNavVariant = ({
  logo = <LogoWithText />,
  hasLightBg = false,
  ctaLink = '/talk-to-us',
  networkLink = '/talk-to-us',
  isNetworkNav = false,
  useLink = false,
  LinkComponent = Link,
  LinkInLogo = "/home",
  LinkExternal= false,
  longButton=false,
  ttuTalkToUs=false,
  isStickyFromParent=true,
  showNueLifeButtons=true,
  showNetworkButtons=false,
  enableModal=false
}) => {
  const [open, setToggleOpen] = useState(false);
  const headerRef = React.createRef();
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });


  React.useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = open
      ? 'hidden'
      : 'auto';

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [open]);

  const handleScroll = (elTopOffset, elHeight) => {
    if (isStickyFromParent) {
      if (window.pageYOffset > elTopOffset + elHeight) {
        setSticky({ isSticky: true, offset: elHeight });
      } else {
        setSticky({ isSticky: false, offset: 0 });
      }
    }
  };



  useEffect(() => {
    var header = headerRef.current?.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top - 43, header.height);
    };

    window.addEventListener('scroll', handleScrollEvent, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [headerRef.current]);

  return (
    <div
      className={classNames('top-nav-variant', {
        open,
        'light-background': sticky.isSticky || hasLightBg,
        nav_background: sticky.isSticky,
        sticky: sticky.isSticky,
      })}>
      <div className="underlay" />
      <div className="placeholder" />

      <div className="nav" ref={headerRef}>
        <Layout className="wrapper" spread horizontal center>
          {LinkExternal? <a href={LinkInLogo}>{logo}</a> : <LinkComponent to={LinkInLogo}>{logo}</LinkComponent>}

          <div className="buttons">
          {showNueLifeButtons && <>
            <Button
              secondary
              className="phone only-desktop cta"
              icon
              long={longButton}
              as={useLink ? Link : LinkComponent}
              onClick={() => {
                window?.gtag('event', 'cta_talk_with_us_clicked');
              }}
              to={isNetworkNav ? networkLink : ctaLink}>
              Get Started
            </Button>
            <div className="only-mobile">
              <Button
                className="compact cta "
                secondary
                icon
                onClick={() => {
                  window?.gtag('event', 'cta_talk_with_us_clicked');
                }}
                as={useLink ? Link : LinkComponent}
                to={isNetworkNav ? networkLink : ctaLink}>
                <span
                  style={{
                    padding: '0 10px',
                  }}>
                  Get Started
                </span>
              </Button>
            </div>
            <div className="toggle" onClick={() => setToggleOpen(!open)}>
              {open && <IconClose />}
              {!open && <IconMenu />}
            </div></>
          }
          {showNetworkButtons && <>
            <Button
              primary
              className="phone only-desktop cta"
              icon
              long={longButton}
              as={useLink ? Link : LinkComponent}
              to={isNetworkNav ? networkLink : ctaLink}>
              I Want to Prescribe
            </Button>
            <div className="only-mobile">
              <Button
                className="compact cta "
                primary
                pill
                as={useLink ? Link : LinkComponent}
                to={isNetworkNav ? networkLink : ctaLink}>
                <span
                  style={{
                    padding: '0 10px', fontSize: '0.7em'
                  }}>
                  Prescribe
                </span>
              </Button>
            </div>
            <Button
              primary
              className="phone only-desktop cta"
              icon
              long={longButton}
              onClick={() => {
                enableModal && enableModal();
              }}
              >
              Refer a Patient
            </Button>
            <div className="only-mobile">
              <Button
                className="compact cta "
                primary
                pill
                onClick={() => {
                  enableModal && enableModal();
                }}>
                <span
                  style={{
                    padding: '0 10px', fontSize: '0.7em'
                  }}>
                  Refer
                </span>
              </Button>
            </div>
          </>
          }
          </div>

        </Layout>
      </div>
      <div className={classNames('top-nav-variant__foldout', { open })}>
        <nav>
          <ul className="links">
            <li>
              <LinkComponent to={'/home'}>home</LinkComponent>
            </li>
            <li>
              <LinkComponent to={`/company`}>company</LinkComponent>
            </li>
              {/*
            <li>
              <LinkComponent to={`/nue-care`}>nue care</LinkComponent>
            </li>*/}
            <li>
              <LinkComponent to={`/programs`}>
                programs
              </LinkComponent>
            </li>
            <li>
              <LinkComponent to={`/faq`}>
                faq
              </LinkComponent>
            </li>
            <li>
              <LinkComponent to={`/journal`}>journal</LinkComponent>
            </li>
            <li>
              <LinkComponent to={`/impact`}>impact</LinkComponent>
            </li>
            <li className="loginCTA">
                <a href="https://portal.nue.life/">login</a>
            </li>
          </ul>

          <div className="socialmedia">
            <a
              href="https://www.instagram.com/nue.life.health/"
              target="_blank"
              rel="noreferrer">
              <IconInstagram />
            </a>
            <a
              href="https://www.facebook.com/NueLifeHealth"
              target="_blank"
              rel="noreferrer">
              <IconFacebook />
            </a>
            <a
              href="https://twitter.com/nuelifehealth"
              target="_blank"
              rel="noreferrer">
              <IconTwitter />
            </a>
            <a
              href="https://www.linkedin.com/company/nuelife/"
              target="_blank"
              rel="noreferrer">
              <IconLinkedin />
            </a>
          </div>
        </nav>

        <div className="nav_background" onClick={() => setToggleOpen(false)} />
      </div>
    </div>
  );
};
