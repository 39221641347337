import React from 'react';
import { Button } from '../Button';
import { IconPlus } from '../Icons';
import { Loader } from '../Loader';
import './styles.less';

export const LoadMore = ({ className, onClick }) => {
  const [loading, setLoading] = React.useState(false);

  async function handleOnClick() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  return (
    <Button
      className={['loadMore', className, loading && 'loading']
        .filter(Boolean)
        .join(' ')}
      icon
      secondary
      onClick={handleOnClick}>
      {loading && <Loader />}
      {!loading && (
        <>
          <IconPlus />
          <span className="text">Load more</span>
        </>
      )}
    </Button>
  );
};
