import React from 'react';
import { startCase } from 'lodash';
import { Helmet } from 'react-helmet-async';
import withBEM from './bem-functional';

const layouts = {};

export function useLayout(Component, name) {
  layouts[name] = Component;
}

const defaultDescription =
  "We're a next-gen mental wellness company that believes in the power of psychedelics to catalyze lasting change. Now offering online ketamine treatments!";

export default function withLayout(Component) {
  const screenTitle = startCase(Component.name.replace(/Screen$/, ''));
  const componentTitle = Component.title || screenTitle;
  const componentDescription = Component.description || defaultDescription;
  Component = withBEM(Component);

  return function ScreenWithLayout(props) {
    const Layout = layouts[Component.layout] || NullLayout;
    const { title: metatitle, description: metadescription } = props.meta || {};
    const title = metatitle || componentTitle;
    const description = metadescription || componentDescription;
    const url = `https://${window.location.hostname}${window.location.pathname}`;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta
            property="og:title"
            content="Nue Life - It's your right to feel good."
          />
          <meta property="og:description" content={description} />
          <meta property="og:image" content="/assets/landing-hero-og.jpg" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={url} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta
            property="twitter:image"
            content="/assets/landing-hero-og.jpg"
          />
        </Helmet>
        <Layout>
          <Component {...props} />
        </Layout>
      </>
    );
  };


}

function NullLayout({ children }) {
  return children;
}