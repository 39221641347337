import React from 'react';
import classNames from 'classnames';
import './stepper.less';

export function Stepper({ steps, current }) {
  const stepsArray = [...new Array(steps)];
  return (
    <div className="stepper">
      {stepsArray.map((_, index) => (
        <div
          key={index}
          className={classNames('stepper__item', {
            done: current >= index,
          })}>
          <div className="stepper__dot"></div>
          {index !== stepsArray.length - 1 && (
            <div className="stepper__line"></div>
          )}
        </div>
      ))}
    </div>
  );
}

export function StepperV2({ current, total }) {
  const steps = [...new Array(total)];
  return (
    <div className="stepperv2">
      <div className="indicator">
        {current} / {total}
      </div>
      <div className="bars">
        {steps.map((_, index) => (
          <div
            key={index}
            className={classNames('stepperv2__item', {
              current: current - 1 == index,
            })}></div>
        ))}
      </div>
    </div>
  );
}