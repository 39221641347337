import React, { useState, useEffect } from 'react';
import { IconArrowDown, IconArrowUp } from '../Icons';

import './styles.less';

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const Accordion = ({
  namespace,
  enabled,
  title,
  children,
  withDetails = false,
  ...props
}) => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    if (open && namespace) {
      window?.gtag('event', namespace);
    }
  }, [open]);

  return (
    <a href={`#${slugify(title)}`} onClick={() => setOpen(!open)}>
      <div
        className={['accordion', open ? 'open' : 'closed']
          .join(' ')}>
        <h2 className={props?.className}>
          {title}
          <div>
            {open ? <IconArrowUp /> : <>{/*withDetails && 'Details'*/} <IconArrowDown /></>}
          </div>
        </h2>

        <p>
          <br />
          {children}
        </p>
      </div>
    </a>
  );
};
