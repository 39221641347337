import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useContext,
  useCallback,
} from 'react';

import staticPlans from 'data/plans.json';
import staticPlansForUpgrade from 'data/plansForUpgrade.json';
import staticPlanPrograms from 'data/planPrograms.json';
import staticPlanCare from 'data/plansCare.json';

import useExperiment from 'utils/hooks/useExperiment';

const GlobalContext = createContext({
  token: undefined,
  account: {},
  setAccount: () => { },
  leadQuestions: [],
  setLeadQuestions: () => { },
  flagQuestions: [],
  setFlagQuestions: () => { },
  startInfo: undefined,
  setStartInfo: () => { },
});

GlobalContext.displayName = 'GlobalContext';

function getSafe(key, parse, defaultValue = undefined) {
  const data = window.sessionStorage.getItem(key);
  try {
    return (parse ? JSON.parse(data) : data) || defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

/**
 * @param {string} key to set session storage
 * @param {any} value to set to session storage after stringify
 */
function addToSessionStorage(key, value) {
  if ([null, undefined, ''].includes(value)) {
    return;
  }

  window.sessionStorage.setItem(key, JSON.stringify(value));
}

import { request } from 'utils/api';
import { PUBLIC_TOKEN } from 'utils/env';
import { captureError } from 'utils/sentry';

const AVAILABLE_STATES = ['CA', 'MA', 'TX'];

const GlobalProvider = ({ children }) => {
  // Provider state
  const variant = useExperiment('x9xE8bMAQtWCXbeftfG40g', 'our-process');

  const [error, setError] = useState();
  const [token, setToken] = useState(getSafe('token', false));
  const [account, setAccount] = useState(getSafe('account', true, []));
  const [plans, setPlans] = useState([]);
  const [planPrograms, setPlanPrograms] = useState([]);
  const [plansForUpgrade, setPlansForUpgrade] = useState([]);
  const [plansCare, setPlansCare] = useState([]);
  const [email, setEmail] = useState(getSafe('email', false, ''));
  const [planOnline, setPlanOnline] = useState(true);
  const [checkoutCode, setCheckoutCode] = useState(
    getSafe('checkoutCode', false, '')
  );
  const [checkoutPractice, setCheckoutPractice] = useState('');
  const [availableStates, setAvailableStates] = useState([]);
  const [leadQuestions, setLeadQuestions] = useState(
    getSafe('leadQuestions', true, [])
  );
  const [flagQuestions, setFlagQuestions] = useState(
    getSafe('flagQuestions', true, [])
  );
  const [lockCheckoutOptions, setLockCheckoutOptions] = useState(
    getSafe('lockCheckoutOptions', true, false)
  );

  const dStartInfo = getSafe('startInfo', true);

  const [startInfo, setStartInfo] = useState(
    dStartInfo?.dateOfBirth
      ? { ...dStartInfo, dateOfBirth: new Date(dStartInfo.dateOfBirth) }
      : {}
  );

  // Lifecycle
  useEffect(() => {
    async function boot() {
      try {
        await Promise.all([loadPlans(), loadAvailableStates()]);
      } catch (e) {
        setError(e);
      }
    }

    boot();
  }, []);

  // Add info to local storage
  useEffect(() => {
    window.sessionStorage.setItem('startInfo', JSON.stringify(startInfo));
  }, [startInfo]);

  useEffect(() => {
    addToSessionStorage('flagQuestions', flagQuestions);
  }, [flagQuestions]);

  useEffect(() => {
    addToSessionStorage('lockCheckoutOptions', lockCheckoutOptions);
  }, [lockCheckoutOptions]);

  useEffect(() => {
    addToSessionStorage('email', email);
  }, [email]);

  useEffect(() => {
    addToSessionStorage('checkoutCode', checkoutCode);
  }, [checkoutCode]);

  useEffect(() => {
    addToSessionStorage('leadQuestions', leadQuestions);
  }, [leadQuestions]);

  useEffect(() => {
    addToSessionStorage('account', account);
  }, [account]);

  useEffect(() => {
    addToSessionStorage('token', token);
  }, [token]);

  useEffect(() => {
    if (checkoutPractice !== '') {
      loadAvailableStates();
    }
  }, [checkoutPractice]);

  function resetCheckoutSettings() {
    setLockCheckoutOptions(false);
    setEmail('');
    setCheckoutCode('');
    setAccount({});
  }

  async function loadFlagQuestions() {
    if (flagQuestions.length) {
      return;
    }

    const data = await request({
      method: 'GET',
      path: '/v1/web/profile/red-flag-questions',
      headers: {
        Authorization: `Basic ${PUBLIC_TOKEN}`,
      },
    });

    setFlagQuestions(
      data.content.map((c) => {
        return {
          id: c.flag,
          ...c,
        };
      })
    );
  }

  async function loadAvailableStates() {
    if (!window.practiceCode && availableStates.length) {
      return;
    }
    let data;
    let isFreeUser = window.sessionStorage.getItem('isAppOnlySignUp');
    if (isFreeUser) {
      data = await request({
        method: 'GET',
        path: '/v1/web/profile/all-states',
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });
      setAvailableStates(data.content.map((c) => c.name).sort());
      return;
    }
    let practiceCode =
      window.practiceCode ||
      window.sessionStorage.getItem('practiceCode') ||
      undefined;
    if (practiceCode != undefined) {
      window.sessionStorage.setItem('practiceCode', practiceCode);
    }
    if (checkoutPractice === '') {
      data = await request({
        method: 'GET',
        path:
          practiceCode != undefined && practiceCode != 'undefined'
            ? `/v1/web/practice/states/${practiceCode}`
            : '/v1/web/profile/available-states',
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });
    } else {
      data = await request({
        method: 'GET',
        path: `/v1/web/practice/states/${checkoutPractice}`,
        headers: {
          Authorization: `Basic ${PUBLIC_TOKEN}`,
        },
      });
    }

    if (!data?.content?.length) {
      captureError(
        new Error(`No available states (${window.practiceCode || 'default'})`)
      );
    }

    const computedAvailableStates = practiceCode
      ? data.content.map((c) => c.name).sort()
      : data.content
        .map((c) => c.name)
        .filter((c) => AVAILABLE_STATES.includes(c))
        .sort();

    setAvailableStates(computedAvailableStates);
  }

  async function loadPlans() {
    if (plans.length) {
      return;
    }

    const data = await request({
      method: 'GET',
      path: '/v1/web/products',
      headers: { Authorization: `Basic ${PUBLIC_TOKEN}` },
    });

    setPlans(
      staticPlans.map((plan) => {
        const item = data.content.find((c) => c.name === plan.nameId) || plan;

        return { ...plan, externalId: item.externalId };
      })
    );

    setPlanPrograms(
      staticPlanPrograms.map((plan) => {
        const item = data.content.find((c) => c.name === plan.nameId) || plan;

        return { ...plan, externalId: item.externalId };
      })
    );

    setPlansCare(
      staticPlanCare
        .sort((a, b) => a.price[0].amount - b.price[0].amount)
        .map((plan) => {
          const item = data.content.find((c) => c.name === plan.nameId) || plan;

          return { ...plan, externalId: item.externalId };
        })
    );

    setPlansForUpgrade(
      staticPlansForUpgrade.map((plan) => {
        const item = data.content.find((c) => c.name === plan.nameId) || plan;

        return { ...plan, externalId: item.externalId };
      })
    );
  }

  const values = useMemo(() => {
    return {
      account,
      availableStates,
      checkoutCode,
      checkoutPractice,
      email,
      flagQuestions,
      leadQuestions,
      loadAvailableStates,
      loadFlagQuestions,
      loadPlans,
      lockCheckoutOptions,
      planOnline,
      planPrograms,
      plans,
      plansCare,
      plansForUpgrade,
      resetCheckoutSettings,
      setAccount,
      setCheckoutCode,
      setCheckoutPractice,
      setEmail,
      setFlagQuestions,
      setLeadQuestions,
      setLockCheckoutOptions,
      setPlanOnline,
      setStartInfo,
      setToken,
      startInfo,
      token,
      variant,
    };
  }, [
    account,
    availableStates,
    checkoutCode,
    checkoutPractice,
    email,
    flagQuestions,
    leadQuestions,
    loadAvailableStates,
    loadFlagQuestions,
    loadPlans,
    lockCheckoutOptions,
    planPrograms,
    plans,
    plansCare,
    plansForUpgrade,
    resetCheckoutSettings,
    setAccount,
    setCheckoutCode,
    setCheckoutPractice,
    setEmail,
    setFlagQuestions,
    setLeadQuestions,
    setLockCheckoutOptions,
    setStartInfo,
    setToken,
    startInfo,
    token,
    variant,
  ]);

  return (
    <GlobalContext.Provider value={values}>
      {error && <p>{error.message}</p>}
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider Context');
  }
  return context;
};

export { GlobalProvider, useGlobal, GlobalContext };