import classNames from 'classnames';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import './signup-step-container.less';

function HeaderComponent({ header, title, subtitle }) {
  if (header === undefined) {
    return (
      <Header>
        <Title>{title}</Title>
        {subtitle && <p className="bodycopy2">{subtitle}</p>}
      </Header>
    );
  }

  if (header === null) {
    return (
      <Header>
        <Title></Title>
      </Header>
    );
  }

  return header;
}

export function SignupStepContainer({
  children,
  title,
  subtitle,
  renderButton,
  header,
}) {
  const elementRef = useRef();

  const [width, setWidth] = useState(null);

  const updateWidth = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, [elementRef]);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);

  return (
    <div className="signupStepContainer">
      <HeaderComponent header={header} title={title} subtitle={subtitle} />
      <div ref={elementRef} className="signupStepContainer__right">
        <div className="signupStepContainer__content">{children}</div>
        {renderButton && (
          <>
            <div className="signupStepContainer__continue" />
            {renderButton({ width })}
          </>
        )}
      </div>
    </div>
  );
}

export const Title = ({ children }) => {
  return (
    <h3 className={classNames('signupStep__title', 'title')}>{children}</h3>
  );
};

export const Header = ({ children }) => {
  return <div className={'signupStepContainer__left'}>{children}</div>;
};