import React, { useCallback, forwardRef } from 'react';
import { kebabCase } from 'lodash';

function getClassNames(name, modifiers, extra) {
  const classes = [
    name,
    ...modifiers.filter((m) => m).map((m) => `${name}--${m}`),
  ];

  if (extra) classes.push(extra);
  return classes;
}

export default function withBEM(Component) {
  const block = kebabCase(Component.name);

  return forwardRef((props, ref) => {
    const getBlockClass = useCallback(
      (...args) => {
        const getModifiers = Component.getModifiers || (() => []);
        return getClassNames(
          block,
          getModifiers(...args),
          props.className
        ).join(' ');
      },
      [props.className]
    );

    const getElementClass = useCallback((name, ...modifiers) => {
      return getClassNames(`${block}__${name}`, modifiers).join(' ');
    }, []);

    const getModifierClass = useCallback(
      (modifier) => `${block}--${modifier}`,
      []
    );

    const bemProps = { getBlockClass, getElementClass, getModifierClass };

    return <Component {...props} {...bemProps} ref={ref} />;
  });
}