// react-hot-loader needs to be imported
// before react and react-dom
import 'react-hot-loader';

import 'styles/reset.less';
import 'styles/fonts.less';
import 'styles/atomic.less';
import 'styles/base.less';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { SENTRY_DSN, ENV_NAME } from 'utils/env';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalProvider } from 'context';
import App from './App';
import ScrollToTop from 'components/ScrollToTop';
import LoadingScreen from 'screens/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function storeSearchParamInLocalStorage(searchParams, key) {
  const value = searchParams.get(key);
  if (value) {
    window.localStorage.setItem(key, value);
  }
}

function storeSearchParamInSessionStorage(searchParams, key) {
  const value = searchParams.get(key);
  if (value) {
    window.sessionStorage.setItem(key, value);
  }
}

if (SENTRY_DSN && !['development', 'qa'].includes(ENV_NAME)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['info'] }),
    ],

    // Just tracing for now
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
  });
}

try {
  const searchParams = new URL(document.location).searchParams;
  storeSearchParamInLocalStorage(searchParams, 'utm_source');
  storeSearchParamInLocalStorage(searchParams, 'utm_medium');
  storeSearchParamInLocalStorage(searchParams, 'utm_term');
  storeSearchParamInLocalStorage(searchParams, 'utm_content');
  storeSearchParamInLocalStorage(searchParams, 'utm_campaign');
  storeSearchParamInLocalStorage(searchParams, 'variant');
  storeSearchParamInLocalStorage(searchParams, 'gclid');
  storeSearchParamInLocalStorage(searchParams, 'traffic_source');
  storeSearchParamInSessionStorage(searchParams, 'enthea_partner');

  if (searchParams.get('form_variant')) {
    // this is used to load the right states given the practice
    window.practiceCode = searchParams.get('form_variant');

    if (searchParams.get('variant')) {
      let variant = searchParams.get('variant');

      if (variant != '' && variant != undefined) {
        window.localStorage.setItem('variant', variant);

        window.localStorage.setItem(
          'form_variant',
          searchParams.get('form_variant') + ' [variant: ' + variant + ']'
        );
      }
    } else {
      window.localStorage.setItem(
        'form_variant',
        searchParams.get('form_variant')
      );
    }
  }

  if (searchParams.get('entry_origin')) {
    window.localStorage?.setItem(
      'entry_origin',
      searchParams.get('entry_origin') || window.location.pathname
    );
  } else if (!window.localStorage?.entry_origin) {
    window.localStorage.setItem('entry_origin', window.location.pathname);
  }
} catch (e) { }

const Wrapper = () => (
  <BrowserRouter>
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalProvider>
          <Suspense fallback={<LoadingScreen />}>
            <ScrollToTop />
            <App />
          </Suspense>
        </GlobalProvider>
      </LocalizationProvider>
    </HelmetProvider>
  </BrowserRouter>
);

ReactDOM.render(<Wrapper />, document.getElementById('root'));