import React from 'react';
import './select.less';
import classNames from 'classnames';

export const Select = ({
  style,
  name,
  label,
  type = 'text',
  error,
  onChange = () => {},
  onBlur = () => {},
  options = [],
  icon,
  ...props
}) => {
  return (
    <div
      className={classNames('select', {
        hasValue: !!props.value,
        error: !!error,
      })}>
      <div className="select__container">
        {label && <label htmlFor={name}>{label}</label>}
        <select
          data-id={name}
          title={label}
          onChange={(e) =>
            onChange(e, { name: e.target.name, value: e.target.value,

              // set to innerText of selected option
              text: e.target.options[e.target.selectedIndex].text
            })
          }
          onBlur={(e) => onBlur(e, { name: e.target.name })}
          type={type}
          name={name}
          style={style}
          {...props}>
          {options.map(({ value, text, key }) => {
            return (
              <option data-id={key || value} key={key || value} value={value}>
                {text}
              </option>
            );
          })}
        </select>
        {icon}
      </div>
      {error?.type == 'error' && (
        <div className={'input__error'}>{error.message}</div>
      )}
    </div>
  );
};