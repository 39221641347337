import { API_URL } from 'utils/env';
import { captureError } from 'utils/sentry';
import { ApiError, ApiParseError } from './errors';
import { getToken } from './token';

export default async function request(options) {
  const { method = 'GET', path, files, params, domain = API_URL } = options;
  let { body } = options;

  const token = options.token || getToken();

  const headers = Object.assign(
    {
      Accept: 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    },
    options.headers
  );

  const url = new URL(path, domain);
  url.search = new URLSearchParams(params);

  if (files) {
    const data = new FormData();
    files.forEach((file) => {
      data.append('file', file);
    });
    for (let [key, value] of Object.entries(body || {})) {
      data.append(key, value);
    }
    body = data;
  } else if (!(body instanceof FormData)) {
    body = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }

  const timeout = setTimeout(() => {
    captureError(new Error(`Request is slow (10 secs): ${method} ${url}`));
  }, 10000);

  const res = await fetch(url, {
    method,
    headers,
    body,
  });

  clearTimeout(timeout);

  if (res.status === 204) {
    return;
  }

  if (!res.ok) {
    let message, status, details;
    try {
      const data = await res.clone().json();
      if (!data.success) {
        message = data.responseDetail;
        status = data.responseCode;
      }
    } catch (err) {
      message = await res.clone().text();
    }
    console.error(`API Error ${status} on ${method} ${url}: ${message}`, {
      body,
    });
    throw new ApiError(
      message ||
        res.statusText ||
        `Received a bad response from the API (${res.status})`,
      status || res.status,
      details
    );
  }

  try {
    return await res.json();
  } catch (err) {
    throw new ApiParseError();
  }
}