import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './dialog.less';
import { Button } from '../Button';
import { IconClose } from '../Icons';
import classNames from 'classnames';

export function Dialog(props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, []);

  function onKeyDown(event) {
    if (event.keyCode === 27) {
      props.onClose();
    }
  }

  function onClose() {
    props.onClose();
  }

  function renderDialog() {
    return (
      <div
        onClick={(e) => {
          if (e && e?.target?.className?.match('dialog__background')) {
            onClose();
          }
        }}
        className={classNames('dialog__background', props.className)}>
        {props.onClose && (
          <Button pill eggshell onClick={onClose} className="dialog__close">
            <IconClose />
          </Button>
        )}
        <div className="dialog__container">{props.children}</div>
      </div>
    );
  }

  return ReactDOM.createPortal(renderDialog(), document.body);
}