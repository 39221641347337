import React from 'react';
import { screen } from 'helpers';
import PageCenter from 'components/PageCenter';
import { CircularProgress } from '@mui/material';

@screen
export default class LoadingScreen extends React.Component {
  static layout = 'none';

  render() {
    return (
      <PageCenter>
          <CircularProgress />
      </PageCenter>
    );
  }
}